@keyframes flapDownTop {
  from {
    transform: rotateX(0deg);
  }

  50%,
  to {
    transform: rotateX(90deg);
  }
}

@keyframes flapDownBottom {
  from,
  50% {
    transform: rotateX(90deg);
  }

  90% {
    transform: rotateX(20deg);
  }

  80%,
  to {
    transform: rotateX(0deg);
  }
}

.split-flap-digit {
  color: #1e293b;
  background-color: #94a3b8;
  position: relative;
  display: inline-block;
  box-sizing: content-box;
  width: 1.1ch;
  height: 1em;
  font-family: sans-serif;
  line-height: 2rem;
  font-size: 30px;
  text-align: center;
  margin-right: 2px;
  border-radius: 3px;
}

.split-flap-digit:last-child {
  margin-right: 0;
}

.split-flap-digit[data-mode='words'] {
  width: 100%;
}

.split-flap-digit[data-mode='alpha'] {
  width: 1.7ch;
}

.split-flap-hinge {
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  box-sizing: border-box;
  height: 0.04em;
  background-color: inherit;
}

.split-flap {
  position: absolute;
  height: 100%;
  width: 100%;
  animation-fill-mode: forwards;
  transform-origin: center;
  background-color: inherit;
  background: inherit;
  z-index: 1;
  border-radius: inherit;
  box-sizing: border-box;
}

.split-flap.split-flap-animated {
  animation-duration: 300ms;
  z-index: 2;
}

.split-flap.split-flap-top {
  clip-path: polygon(0 50%, 100% 50%, 100% 0, 0 0);
}

.split-flap.split-flap-top.split-flap-animated {
  transform: rotateX(50deg);
  opacity: 0.4;
}

.split-flap.split-flap-top.split-flap-animated.split-flap-final {
  opacity: 1;
  animation-name: flapDownTop;
  animation-timing-function: ease-in;
}

.split-flap.split-flap-bottom {
  clip-path: polygon(0 100%, 100% 100%, 100% 50%, 0 50%);
}

.split-flap.split-flap-bottom.split-flap-animated.split-flap-final {
  animation-name: flapDownBottom;
  animation-timing-function: ease-out;
}
